<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="mb-4 h4 font-weight-bold text-color-green">
          Detail Pemeriksaan {{ this.patientVisitData.checkup_date }}
        </div>
        <div class="card">
          <div class="card-body background-blue">
            <div class="">
              <div class="row">
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Nama
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{
                          this.patientVisitData.Patient.name
                            ? this.patientVisitData.Patient.name
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Tanggal Lahir
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Usia
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Cara Pembayaran
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.payment_method }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        No. BPJS
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">123456</td>
                    </tr>

                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        DPJP
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{
                          this.patientVisitData.Doctor.User.name
                            ? this.patientVisitData.Doctor.User.name
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Spesialisasi
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{
                          this.patientVisitData.Doctor.Specialization.name
                            ? this.patientVisitData.Doctor.Specialization.name
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Koder
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{
                          this.patientVisitData.Coder
                            ? this.patientVisitData.Coder.name
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Verifikator
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{
                          this.patientVisitData.Verifikator
                            ? this.patientVisitData.Verifikator.name
                            : ""
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        No. SEP
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">123456</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        No. RM
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{
                          this.patientVisitData.Patient.medical_record_no
                            ? this.patientVisitData.Patient.medical_record_no
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        No. Kunjungan
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{
                          this.patientVisitData.noKunjungan
                            ? this.patientVisitData.noKunjungan
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Waktu Pemeriksaan
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.checkup_time }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-xl-8">
              <div class="card">
                <div class="card-body">
                  <a-tabs :default-active-key="setTab()">
                    <a-tab-pane key="1" tab="Resume Medis">
                      <div>
                        <div class="d-flex">
                          <a-button
                            type="secondary"
                            class="d-flex justify-content-center align-items-center"
                            style="border-radius: 26px"
                            size="large"
                          >
                            <img
                              src="resources/images/pdf.png"
                              alt="Icon"
                              style="height: 20px; width: 20px"
                            />
                            <p
                              class="m-0 ml-2"
                              style="font-size: 14px; color: #428a94"
                            >
                              PDF
                            </p>
                          </a-button>
                          <a-button
                            type="secondary"
                            class="d-flex justify-content-center align-items-center ml-2"
                            style="border-radius: 26px"
                            size="large"
                            @click="handleToggleModal"
                            v-if="
                              (userRole === 'koder' || userRole === 'dokter') &&
                              patientVisitData.status !==
                                'Selesai Verifikasi Berkas'
                            "
                          >
                            <img
                              src="resources/images/edit.svg"
                              alt="Icon"
                              style="height: 20px; width: 20px"
                            />
                            <p
                              class="m-0 ml-2"
                              style="font-size: 14px; color: #428a94"
                            >
                              Ubah
                              {{
                                userRole === "koder"
                                  ? "Kode Diagnosis/Tindakan"
                                  : "SOAP"
                              }}
                            </p>
                          </a-button>
                        </div>
                        <div class="h5 font-weight-bold mt-3">Catatan SOAP</div>
                        <br />
                        <div class="font-weight-bold SOAP-title-color">
                          Subjective
                        </div>
                        <div
                          v-html="
                            formatText(
                              this.patientVisitData.DoctorNote.subjective,
                            )
                          "
                        ></div>
                        <br />
                        <div class="font-weight-bold SOAP-title-color">
                          Objective
                        </div>
                        <div
                          v-html="
                            formatText(
                              this.patientVisitData.DoctorNote.objective,
                            )
                          "
                        ></div>
                        <br />
                        <div class="font-weight-bold SOAP-title-color">
                          Assessment
                        </div>
                        <div
                          v-html="
                            formatText(
                              this.patientVisitData.DoctorNote.assessment,
                            )
                          "
                          class="my-1"
                        ></div>
                        <div class="my-1">
                          <div class="font-weight-bold">Diagnosis Primer:</div>
                          <div>
                            •
                            {{ this.primary_condition[0].code }}
                          </div>
                        </div>
                        <div class="my-1">
                          <div class="font-weight-bold">
                            Diagnosis Sekunder:
                          </div>
                          <div
                            v-for="condition in this.secondary_condition"
                            :key="condition.code"
                          >
                            • {{ condition.code }}
                          </div>
                          <div
                            v-if="this.patientVisitData.Conditions.length === 1"
                          >
                            -
                          </div>
                        </div>
                        <div class="font-weight-bold SOAP-title-color">
                          Plan
                        </div>
                        <div
                          v-html="
                            formatText(this.patientVisitData.DoctorNote.plan)
                          "
                        ></div>
                        <div class="font-weight-bold">Kode Tindakan:</div>
                        <div
                          v-for="action in this.tindakan_condition"
                          :key="action.code"
                        >
                          • {{ action.code }}
                        </div>
                        <div
                          v-if="
                            this.patientVisitData.DoctorNote.Procedures
                              .length === 0
                          "
                        >
                          -
                        </div>
                        <br />
                        <a-divider />
                        <!-- SAMPESINI -->
                      </div>
                      <div class="h5 font-weight-bold">Anemesis</div>
                      <br />
                      <div class="font-weight-bold SOAP-title-color">
                        Keluhan Utama
                      </div>
                      <div
                        v-for="(keluhan, index) in this.patientVisitData
                          .Anamnesis.ChiefComplaints"
                        :key="index"
                      >
                        <div>• {{ keluhan.keterangan }}</div>
                      </div>
                      <br />
                      <div class="font-weight-bold SOAP-title-color">
                        Riwayat Penyakit
                      </div>
                      <div>
                        <table class="table">
                          <thead>
                            <th
                              style="
                                width: 200px;
                                height: 32px;
                                padding-right: 15px;
                              "
                            >
                              Kategori
                            </th>
                            <th>Nama Penyakit</th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(riwayat, index) in this.patientVisitData
                                .Anamnesis.IllnessHistories"
                              :key="index"
                            >
                              <td v-if="riwayat.type === 'personal'">
                                Riwayat Penyakit Pribadi
                              </td>
                              <td v-if="riwayat.type === 'family'">
                                Riwayat Penyakit Keluarga
                              </td>
                              <td>{{ riwayat.display }}</td>
                            </tr>
                            <tr
                              v-if="
                                this.patientVisitData.Anamnesis.IllnessHistories
                                  .length === 0
                              "
                            >
                              <td>Tidak ada data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <div class="font-weight-bold SOAP-title-color">
                        Riwayat Alergi
                      </div>
                      <div>
                        <table class="table">
                          <thead>
                            <th
                              style="
                                width: 200px;
                                height: 32px;
                                padding-right: 15px;
                              "
                            >
                              Jenis Alergi
                            </th>
                            <th>Nama Alergen</th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="allergy in this.patientVisitData.Anamnesis
                                .Allergies"
                              :key="allergy.id"
                            >
                              <!-- <td>{{ allergy.type }}</td> -->
                              <td v-if="allergy.type === 1">Medication</td>
                              <td v-if="allergy.type === 2">Food</td>
                              <td v-if="allergy.type === 3">Environment</td>
                              <td v-if="allergy.type === 4">Biologic</td>
                              <td>{{ allergy.display }}</td>
                            </tr>
                            <tr
                              v-if="
                                this.patientVisitData.Anamnesis.Allergies
                                  .length === 0
                              "
                            >
                              <td>Tidak ada data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <div class="font-weight-bold SOAP-title-color">
                        Riwayat Pengobatan
                      </div>
                      <div>
                        <table class="table">
                          <thead>
                            <th style="height: 32px; padding-right: 15px">
                              Nama Obat
                            </th>
                            <th>Dosis dan Frekuensi</th>
                            <th>Status</th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="medication in this.patientVisitData
                                .Anamnesis.MedicationStatements"
                              :key="medication.id"
                            >
                              <td>
                                {{ medication.name ? medication.name : "" }}
                              </td>
                              <td>{{ medication.dosage }}</td>
                              <td>
                                {{
                                  medicineStatus.find(
                                    (item) => item.value === medication.status,
                                  ).label
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                this.patientVisitData.Anamnesis
                                  .MedicationStatements.length === 0
                              "
                            >
                              <td>Tidak ada data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <a-divider />
                      <table>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold SOAP-title-color"
                          >
                            Suhu
                          </td>
                          <td style="padding-left: 15px">
                            {{ this.patientVisitData.Anamnesis.suhu }} °C
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Tekanan Darah Sistolik
                          </td>
                          <td style="padding-left: 15px">
                            {{
                              this.patientVisitData.Anamnesis
                                .tekanan_darah_sistolik
                            }}
                            mmHg
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Tekanan Darah Diastolik
                          </td>
                          <td style="padding-left: 15px">
                            {{
                              this.patientVisitData.Anamnesis
                                .tekanan_darah_diastolik
                            }}
                            mmHg
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Saturasi Oksigen
                          </td>
                          <td style="padding-left: 15px">
                            {{
                              this.patientVisitData.Anamnesis.saturasi_oksigen
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Denyut Jantung
                          </td>
                          <td style="padding-left: 15px">
                            {{ this.patientVisitData.Anamnesis.nadi }}
                            kali/menit
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Frekuensi Nafas
                          </td>
                          <td style="padding-left: 15px">
                            {{
                              this.patientVisitData.Anamnesis.frekuensi_nafas
                            }}
                            kali/menit
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Tinggi Badan
                          </td>
                          <td
                            v-if="this.patientVisitData.Anamnesis.tinggi_badan"
                            style="padding-left: 15px"
                          >
                            {{ this.patientVisitData.Anamnesis.tinggi_badan }}
                            cm
                          </td>
                          <td v-else style="padding-left: 15px">-</td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Berat Badan
                          </td>
                          <td
                            v-if="this.patientVisitData.Anamnesis.berat_badan"
                            style="padding-left: 15px"
                          >
                            {{ this.patientVisitData.Anamnesis.berat_badan }} kg
                          </td>
                          <td v-else style="padding-left: 15px">-</td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Lingkar Perut
                          </td>
                          <td
                            v-if="this.patientVisitData.Anamnesis.lingkar_perut"
                            style="padding-left: 15px"
                          >
                            {{ this.patientVisitData.Anamnesis.lingkar_perut }}
                            cm
                          </td>
                          <td v-else style="padding-left: 15px">-</td>
                        </tr>
                      </table>
                      <a-divider />
                      <div class="h5 font-weight-bold">Obat</div>
                      <br />
                      <div class="font-weight-bold SOAP-title-color">Resep</div>
                      <table class="table">
                        <thead>
                          <th style="height: 32px; padding-right: 15px">
                            Nama Obat
                          </th>
                          <th>Dosis dan Frekuensi</th>
                        </thead>
                        <tbody>
                          <tr
                            v-for="medication in this.patientVisitData
                              .DoctorNote.MedicationRequests"
                            :key="medication.id"
                          >
                            <td>
                              {{ medication.name ? medication.name : "" }}
                            </td>
                            <td>
                              {{ medication.quantity }} ({{
                                medication.dosage
                              }}
                              x {{ medication.frequency }},
                              {{
                                medication.use_morning === true ? "Pagi" : ""
                              }}
                              {{ medication.use_noon === true ? "Siang" : "" }}
                              {{
                                medication.use_night === true ? "Malam" : ""
                              }})
                            </td>
                          </tr>
                          <tr
                            v-if="
                              this.patientVisitData.Anamnesis
                                .MedicationStatements.length === 0
                            "
                          >
                            <td>Tidak ada data</td>
                          </tr>
                        </tbody>
                      </table>

                      <br />
                      <div class="font-weight-bold SOAP-title-color">
                        Catatan Resep
                      </div>
                      <div
                        v-if="this.patientVisitData.DoctorNote.medication_note"
                        v-html="
                          formatText(
                            this.patientVisitData.DoctorNote.medication_note,
                          )
                        "
                      ></div>
                      <div v-else>Tidak Ada</div>
                      <br />
                      <div
                        class="w-100 d-flex flex-column align-items-end justify-content-center"
                      >
                        <div
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                          <p>Kota Jakarta Selatan, 10 Juli 2024</p>

                          <div
                            class="w-100 d-flex flex-column align-items-end justify-content-center"
                          >
                            <div
                              class="d-flex flex-column align-items-center justify-content-center"
                            >
                              <p>
                                Kota Jakarta Selatan,
                                {{ patientVisitData.date }}
                              </p>

                              <p>Dokter Penanggung Jawab Pasien</p>
                              <div
                                style="
                                  width: 100px;
                                  height: 100px;
                                  background-color: #c4c4c4;
                                  align-items: end;
                                "
                              ></div>
                              <p class="mt-2">
                                {{
                                  this.patientVisitData.Doctor.User.name
                                    ? this.patientVisitData.Doctor.User.name
                                    : ""
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="SEP"> </a-tab-pane>
                    <a-tab-pane key="3" tab="Penunjang">
                      <div class="h5 font-weight-bold">Penunjang</div>
                      <div v-if="this.patientVisitDocuments.length === 0">
                        Pasien ini belum memiliki dokumen penunjang.
                      </div>
                      <div
                        v-for="file in this.patientVisitDocuments"
                        :key="file.id"
                      >
                        <div>
                          Diunggah oleh {{ file.created_by_name }} pada
                          {{ formatDateTimeFull(file.VisitDocument.createdAt) }}
                        </div>
                        <div v-if="file.media_mime_type !== 'application/pdf'">
                          <div class="image-container">
                            <object
                              :data="file.uri"
                              :type="file.media_mime_type"
                              width="400px"
                              min-height="100%"
                            ></object>
                            <button
                              class="zoom-button"
                              @click="openViewer(file.uri, file)"
                            >
                              <img
                                src="resources/images/zoom-icon.svg"
                                alt="Zoom In"
                                width="20px"
                                height="20px"
                              />
                            </button>
                          </div>
                        </div>
                        <div v-else style="min-width: 50vw">
                          <object
                            :data="file.uri"
                            :type="file.media_mime_type"
                            width="100%"
                            height="700px"
                          ></object>
                        </div>
                        <a :href="file.uri" target="_blank">{{
                          file.name ? file.name : ""
                        }}</a>
                        <br /><br />
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="4" tab="Rincian Tagihan"> </a-tab-pane>
                  </a-tabs>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="h5 font-weight-bold SOAP-title-color">
                Verifikasi Berkas Klaim
              </div>
              <div v-if="userRole === 'fileReviewer'">
                <div
                  class="card w-100 p-4 mt-4"
                  style="background-color: #fff4f5; border-radius: 16px"
                  v-if="rekomendasiVerifikasi"
                >
                  <div
                    class="h5 font-weight-bold d-flex justify-content-baseline"
                    style="margin-left: 11px"
                  >
                    <img
                      src="resources/images/info-icon.svg"
                      alt="Icon"
                      style="height: 24px; width: 24px"
                    />
                    <p class="m-0 ml-2">Rekomendasi Verifikasi</p>
                  </div>
                  <ul class="mt-3">
                    <li
                      v-for="(recomend, idx) in recommendationVerification"
                      :key="idx"
                    >
                      Terdapat permintaan penunjang laboratorium
                      <span style="font-weight: bold">{{ recomend }}</span> pada
                      kunjungan ini. Namun, belum ada hasil pemeriksaan
                      <span style="font-weight: bold">{{ recomend }}</span
                      >.
                    </li>
                  </ul>
                </div>
                <div
                  class="card w-100 px-2 py-3 mt-4"
                  style="background-color: #f9fdff; border-radius: 16px"
                  v-else
                >
                  <div
                    class="h5 font-weight-bold d-flex justify-content-baseline"
                    style="margin-left: 11px"
                  >
                    <img
                      src="resources/images/info-icon.svg"
                      alt="Icon"
                      style="height: 24px; width: 24px"
                    />
                    <p class="m-0 ml-2">Rekomendasi Verifikasi</p>
                  </div>
                  <p class="m-0 px-3">
                    Berkas klaim sudah lengkap. Mohon cek kembali konsistensi
                    data diagnosis, tindakan/prosedur, dan berkas penunjang yang
                    ada.
                  </p>
                </div>
              </div>
              <div
                class="card p-4 mt-4"
                style="border-radius: 16px; overflow-y: auto; max-height: 280px"
                v-if="codeLogList.length && !detailLogCode.id"
              >
                <h5 class="font-weight-bold">
                  Log Perubahan Kode Diagnosis/Tindakan
                </h5>
                <div
                  style="cursor: pointer"
                  v-for="(log, idx) in codeLogList"
                  :key="idx"
                  @click="handleChangeLogCode(log)"
                  class="border-bottom"
                >
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    {{ log.date }}
                  </p>
                  <p class="">Diubah oleh {{ log.User.name }}</p>
                </div>
              </div>
              <div
                class="card p-4 mt-4"
                style="border-radius: 16px"
                v-if="!codeLogList.length && !detailLogCode.id"
              >
                <h5 class="font-weight-bold">
                  Log Perubahan Kode Diagnosis/Tindakan
                </h5>
                <p>Belum ada perubahan</p>
              </div>

              <!-- Detail Log Kode -->
              <div
                class="card p-4 mt-4"
                style="border-radius: 16px"
                v-if="detailLogCode.id"
              >
                <div class="d-flex align-items-center">
                  <img
                    src="resources/images/back.svg"
                    alt="Icon"
                    style="height: 24px; width: 24px; cursor: pointer"
                    @click="handleBackLogCodeDetail"
                  />
                  <h5 class="font-weight-bold m-0 ml-3">
                    Detail Perubahan Kode
                  </h5>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Waktu Perubahan
                  </p>
                  <p class="">{{ detailLogCode.date }}</p>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Diubah Oleh
                  </p>
                  <p class="">
                    {{ detailLogCode.User.name ? detailLogCode.User.name : "" }}
                  </p>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Diagnosis Primer
                  </p>
                  <p
                    class=""
                    v-if="
                      !detailLogCode.oldPrimary.length &&
                      !detailLogCode.primary.length
                    "
                  >
                    Tidak ada perubahan
                  </p>
                  <div v-else>
                    <p class="m-0 mb-2">
                      Kode lama oleh
                      {{
                        detailLogCode.oldPrimary[0].User.Roles[0].name
                          ? detailLogCode.oldPrimary[0].User.Roles[0].name
                          : ""
                      }}:
                    </p>
                    <ul class="m-0">
                      <li
                        v-for="(item, idx) in detailLogCode.oldPrimary"
                        :key="idx"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                    <div class="mt-2">
                      <p class="m-0 mb-2">Kode baru oleh Koder:</p>
                      <ul class="m-0">
                        <li
                          v-for="(item, idx) in detailLogCode.primary"
                          :key="idx"
                        >
                          {{ item.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Diagnosis Sekunder
                  </p>
                  <p
                    class=""
                    v-if="
                      !detailLogCode.oldSecondary.length &&
                      !detailLogCode.secondary.length
                    "
                  >
                    Tidak ada perubahan
                  </p>
                  <div v-else>
                    <p class="m-0 mb-2">
                      Kode lama oleh
                      {{ detailLogCode.oldSecondary[0].User.Roles[0].name }}:
                    </p>
                    <ul class="m-0">
                      <li
                        v-for="(item, idx) in detailLogCode.oldSecondary"
                        :key="idx"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                    <div class="mt-2">
                      <p class="m-0 mb-2">Kode baru oleh Koder:</p>
                      <ul class="m-0">
                        <li
                          v-for="(item, idx) in detailLogCode.secondary"
                          :key="idx"
                        >
                          {{ item.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Tindakan
                  </p>
                  <p
                    class=""
                    v-if="
                      !detailLogCode.oldProcedure.length &&
                      !detailLogCode.procedure.length
                    "
                  >
                    Tidak ada perubahan
                  </p>
                  <div v-else>
                    <p class="m-0 mb-2">
                      Kode lama oleh
                      {{ detailLogCode.oldProcedure[0].User.Roles[0].name }}:
                    </p>
                    <ul class="m-0">
                      <li
                        v-for="(item, idx) in detailLogCode.oldProcedure"
                        :key="idx"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                    <div class="mt-2">
                      <p class="m-0 mb-2">Kode baru oleh Koder:</p>
                      <ul class="m-0">
                        <li
                          v-for="(item, idx) in detailLogCode.procedure"
                          :key="idx"
                        >
                          {{ item.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- INI SOAP LOG -->
              <div
                class="card p-4 mt-4"
                style="border-radius: 16px; overflow-y: auto; max-height: 280px"
                v-if="soapLogList.length && !detailLogSoap.id"
              >
                <h5 class="font-weight-bold">Log Perubahan SOAP</h5>
                <div>
                  <div
                    style="cursor: pointer"
                    v-for="(logSoap, idx) in soapLogList"
                    :key="idx"
                    @click="handleChangeLogSoap(logSoap)"
                    class="border-bottom"
                  >
                    <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                      {{ logSoap.date }}
                    </p>
                    <p class="">Diubah oleh {{ logSoap.User.name }}</p>
                  </div>
                </div>
              </div>
              <div
                class="card p-4 mt-4"
                style="border-radius: 16px"
                v-if="!soapLogList.length && !detailLogSoap.id"
              >
                <h5 class="font-weight-bold">Log Perubahan SOAP</h5>
                <p class="m-0 mt-2">Belum ada perubahan</p>
              </div>

              <div
                class="card p-4 mt-4"
                style="border-radius: 16px"
                v-if="detailLogSoap.id"
              >
                <div class="d-flex align-items-center">
                  <img
                    src="resources/images/back.svg"
                    alt="Icon"
                    style="height: 24px; width: 24px; cursor: pointer"
                    @click="handleBackLogSoapDetail"
                  />
                  <h5 class="font-weight-bold m-0 ml-3">
                    Detail Perubahan SOAP
                  </h5>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Waktu Perubahan
                  </p>
                  <p class="">{{ detailLogSoap.date }}</p>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Diubah Oleh
                  </p>
                  <p class="">
                    {{ detailLogSoap.User.name ? detailLogSoap.User.name : "" }}
                  </p>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Subjective
                  </p>
                  <p
                    class=""
                    v-if="
                      !detailLogSoap.oldSubjective.length &&
                      !detailLogSoap.subjective.length
                    "
                  >
                    Tidak ada perubahan
                  </p>
                  <div v-else>
                    <p class="m-0 mb-2">Data lama</p>
                    <ul class="m-0">
                      <li
                        v-for="(item, idx) in detailLogSoap.oldSubjective"
                        :key="idx"
                      >
                        {{ item.content }}
                      </li>
                    </ul>
                    <div class="mt-2">
                      <p class="m-0 mb-2">Data baru</p>
                      <ul class="m-0">
                        <li
                          v-for="(item, idx) in detailLogSoap.subjective"
                          :key="idx"
                        >
                          {{ item.content }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Objective
                  </p>
                  <p
                    class=""
                    v-if="
                      !detailLogSoap.oldObjective.length &&
                      !detailLogSoap.objective.length
                    "
                  >
                    Tidak ada perubahan
                  </p>
                  <div v-else>
                    <p class="m-0 mb-2">Data lama</p>
                    <ul class="m-0">
                      <li
                        v-for="(item, idx) in detailLogSoap.oldObjective"
                        :key="idx"
                      >
                        {{ item.content }}
                      </li>
                    </ul>
                    <div class="mt-2">
                      <p class="m-0 mb-2">Data baru</p>
                      <ul class="m-0">
                        <li
                          v-for="(item, idx) in detailLogSoap.objective"
                          :key="idx"
                        >
                          {{ item.content }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Assessment
                  </p>
                  <p
                    class=""
                    v-if="
                      !detailLogSoap.oldAssessment.length &&
                      !detailLogSoap.assessment.length
                    "
                  >
                    Tidak ada perubahan
                  </p>
                  <div v-else>
                    <p class="m-0 mb-2">Data lama</p>
                    <ul class="m-0">
                      <li
                        v-for="(item, idx) in detailLogSoap.oldAssessment"
                        :key="idx"
                      >
                        {{ item.content }}
                      </li>
                    </ul>
                    <div class="mt-2">
                      <p class="m-0 mb-2">Data baru</p>
                      <ul class="m-0">
                        <li
                          v-for="(item, idx) in detailLogSoap.assessment"
                          :key="idx"
                        >
                          {{ item.content }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                    Plan
                  </p>
                  <p
                    class=""
                    v-if="
                      !detailLogSoap.oldPlan.length &&
                      !detailLogSoap.plan.length
                    "
                  >
                    Tidak ada perubahan
                  </p>
                  <div v-else>
                    <p class="m-0 mb-2">Data lama</p>
                    <ul class="m-0">
                      <li
                        v-for="(item, idx) in detailLogSoap.oldPlan"
                        :key="idx"
                      >
                        {{ item.content }}
                      </li>
                    </ul>
                    <div class="mt-2">
                      <p class="m-0 mb-2">Data baru</p>
                      <ul class="m-0">
                        <li
                          v-for="(item, idx) in detailLogSoap.plan"
                          :key="idx"
                        >
                          {{ item.content }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Detail Log SOAP -->

              <div
                class="card p-4 mt-4"
                style="border-radius: 16px"
                v-if="!detailClarification.id"
              >
                <h5 class="font-weight-bold">Komentar Klarifikasi</h5>
                <!-- card untuk list klarifikasi -->
                <div
                  class="card px-4 py-3 mt-3"
                  style="
                    border-radius: 16px;
                    background-color: #f9fdff;
                    border: 1px solid #428a94;
                  "
                  v-for="(item, idx) in listKlarifikasi"
                  :key="idx"
                  @click="handleDetailKlarifikasi(item)"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h6 class="m-0">
                      Klarifikasi
                      {{
                        item.ReceiverUser.Roles[0].name === "dokter"
                          ? "DPJP"
                          : "Koder"
                      }}
                    </h6>
                    <h6 class="m-0">{{ item.date }}</h6>
                  </div>
                  <p class="m-0 my-3">{{ item.subject }}</p>
                  <div class="d-flex">
                    <div
                      class="d-flex align-items-center justify-content-center py-1"
                      style="
                        border-radius: 20px;
                        background-color: #e1f1ff;
                        width: 130px;
                      "
                      v-if="item.status === 'Proses Klarifikasi'"
                    >
                      <p class="m-0" style="color: #2e90fa">
                        {{ item.status }}
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center py-1"
                      style="
                        border-radius: 20px;
                        background-color: #e1fbea;
                        width: 130px;
                      "
                      v-else
                    >
                      <p class="m-0" style="color: #16b364">
                        {{ item.status }}
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center py-1 ml-2"
                      style="
                        border-radius: 20px;
                        background-color: #fff6db;
                        width: 130px;
                      "
                      v-if="
                        item.status === 'Proses Klarifikasi' &&
                        item.KlarifikasiChats.sender !== userId
                      "
                    >
                      <p class="m-0" style="color: #f79009">Balasan Baru</p>
                    </div>
                  </div>
                </div>
                <!-- ini form input klarifikasi  -->
                <div
                  v-if="
                    toggleCreateClarification && userRole === 'fileReviewer'
                  "
                >
                  <div class="my-2">
                    <p class="m-0 mb-1">Minta Klarifikasi Ke</p>
                    <a-select
                      ref="select"
                      size="medium"
                      class="w-100"
                      v-model="templateKlarifikasi.receiver"
                    >
                      <a-select-option value="" selected
                        >Pilih Penerima Klarifikasi</a-select-option
                      >
                      <a-select-option
                        v-for="(receiver, idx) in receiverList"
                        :key="idx"
                        :value="receiver.id"
                        >{{ receiver.name }} |
                        {{ receiver.role }}</a-select-option
                      >
                    </a-select>
                  </div>
                  <div class="my-2">
                    <p class="m-0 mb-1">Subject</p>
                    <a-input
                      size="medium"
                      placeholder="Masukkan Subject..."
                      v-model="templateKlarifikasi.subject"
                    ></a-input>
                  </div>
                  <div class="my-2">
                    <p class="m-0 mb-1">Komentar</p>
                    <a-textarea
                      placeholder="Masukkan Komentar..."
                      :rows="4"
                      v-model="templateKlarifikasi.comment"
                    />
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <a-button
                      type="primary"
                      size="large"
                      style="
                        border-radius: 26px;
                        width: 123px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                      @click="createClarificationData()"
                    >
                      <img
                        src="resources/images/reply.svg"
                        alt="Icon"
                        style="height: 24px; width: 24px"
                      />
                      <p class="m-0 ml-2">Kirim</p>
                    </a-button>
                    <p
                      class="m-0 ml-3"
                      style="color: #428a94; cursor: pointer"
                      @click="handleToggleClarificationForm"
                    >
                      Batal
                    </p>
                  </div>
                </div>

                <div
                  class="d-flex align-items-center"
                  style="cursor: pointer"
                  v-else-if="
                    !toggleCreateClarification &&
                    userRole === 'fileReviewer' &&
                    patientVisitData.status !== 'Selesai Verifikasi Berkas'
                  "
                  @click="handleToggleClarificationForm"
                >
                  <img
                    src="resources/images/plus-icon.svg"
                    alt="Icon"
                    style="height: 16px; width: 16px"
                  />
                  <p class="m-0 ml-2 SOAP-title-color">
                    Rekomendasi Verifikasi
                  </p>
                </div>
              </div>
              <div class="card p-4 mt-4" style="border-radius: 16px" v-else>
                <div class="d-flex align-items-center">
                  <img
                    src="resources/images/back.svg"
                    alt="Icon"
                    style="height: 24px; width: 24px; cursor: pointer"
                    @click="cancelDetailClarification"
                  />
                  <h5 class="font-weight-bold m-0 ml-3">
                    Klarifikasi
                    {{
                      detailClarification.ReceiverUser.Roles[0].name ===
                      "dokter"
                        ? "DPJP"
                        : "Koder"
                    }}
                    - {{ detailClarification.subject }}
                  </h5>
                </div>
                <div class="mt-2">
                  <div
                    class="d-flex align-items-center justify-content-center py-1"
                    style="
                      border-radius: 20px;
                      background-color: #e1f1ff;
                      width: 130px;
                      margin-left: 37px;
                    "
                    v-if="detailClarification.status === 'Proses Klarifikasi'"
                  >
                    <p class="m-0" style="color: #2e90fa">
                      {{ detailClarification.status }}
                    </p>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-center py-1"
                    style="
                      border-radius: 20px;
                      background-color: #e1fbea;
                      width: 130px;
                    "
                    v-else
                  >
                    <p class="m-0" style="color: #16b364">
                      {{ detailClarification.status }}
                    </p>
                  </div>
                  <a-divider></a-divider>
                  <div v-for="(chat, idx) in chats" :key="idx">
                    <div
                      class="card p-3 border-0"
                      :style="{
                        backgroundColor:
                          chat.id === userId ? '#f2f4f8' : '#f9fdff',
                      }"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <p>
                          {{ chat.id === userId ? "Saya" : chat.name }}
                        </p>
                        <p>{{ chat.date }}</p>
                      </div>
                      <p class="m-0">
                        {{ chat.message }}
                      </p>
                    </div>
                    <!-- <div class="card p-3" style="background-color: #f9fdff">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <p>Dr. Nur Fajar, Sp.Jp</p>
                        <p>12 Juli 2024, 17:30 (2 hari lalu)</p>
                      </div>
                      <p class="m-0">
                        {{ chat.message }}
                      </p>
                    </div> -->
                  </div>
                  <div
                    v-if="detailClarification.status === 'Proses Klarifikasi'"
                  >
                    <div class="row px-3">
                      <a-input
                        type="text"
                        class="col-9"
                        placeholder="Balas Komentar..."
                        size="large"
                        v-model="replyChat"
                      ></a-input>
                      <div class="col-3 pr-0">
                        <a-button
                          type="primary"
                          class="w-100"
                          size="large"
                          @click="handleCreateChat"
                        >
                          <img
                            src="resources/images/reply.svg"
                            alt="Icon"
                            style="height: 24px; width: 24px"
                          />
                        </a-button>
                      </div>
                    </div>
                    <div
                      class="row px-3 mt-3"
                      v-if="userRole === 'fileReviewer'"
                    >
                      <a-button
                        type="secondary"
                        class="w-100 d-flex justify-content-center align-items-center"
                        style="border-radius: 26px"
                        size="large"
                        @click="handleFinishClarification"
                      >
                        <img
                          src="resources/images/green-checklist.svg"
                          alt="Icon"
                          style="height: 24px; width: 24px"
                        />
                        <p
                          class="m-0 ml-2"
                          style="font-size: 16px; color: #428a94"
                        >
                          Tandai Selesai Klarifikasi
                        </p>
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
              <a-button
                type="primary"
                class="w-30 d-flex justify-content-center align-items-center"
                style="border-radius: 26px; height: 40px"
                v-if="
                  userRole === 'fileReviewer' &&
                  patientVisitData.status !== 'Selesai Verifikasi Berkas'
                "
                @click="openModalSubmit"
              >
                <img
                  src="resources/images/checklist.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px"
                />
                <p class="m-0 ml-2" style="font-size: 16px">
                  Selesai Verifikasi
                </p>
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="toggleModal"
      centered
      width="1000px"
      :footer="null"
      :closable="false"
    >
      <div class="mb-4 h4 font-weight-bold text-color-green">
        {{
          userRole === "dokter"
            ? "Ubah Catatan SOAP"
            : "Ubah Kode Diagnosis dan Tindakan"
        }}
      </div>
      <div class="row mt-5 mb-5" v-if="userRole === 'dokter'">
        <div class="col-6">
          Subjective <span style="color: red">*</span>
          <a-textarea
            class="mt-2"
            placeholder="Basic usage"
            :rows="8"
            v-model="patientVisitData.DoctorNote.subjective"
          />
        </div>
        <div class="col-6">
          Objective<span style="color: red">*</span>
          <a-tooltip placement="right">
            <template #title>
              <span
                >Template pemeriksaan fisik dibuat secara otomatis oleh sistem
                berdasarkan spesialisasi dokter. Anda dapat mengubah atau
                menghapusnya sesuai kebutuhan. Isi secara manual atau rekam sesi
                asesmen untuk mengisi objective secara otomatis.</span
              >
            </template>
            <InfoCircleOutlined class="ml-3" />
          </a-tooltip>
          <a-textarea
            class="mt-2"
            placeholder="Basic usage"
            :rows="8"
            v-model="patientVisitData.DoctorNote.objective"
          />
        </div>
        <div class="col-6 mt-4">
          Assesment<span style="color: red">*</span>
          <a-tooltip placement="right">
            <template #title>
              <span
                >Assessment dari kunjungan pasien sebelumnya ditambahkan secara
                otomatis ke dalam kotak area. Anda dapat mengubah atau menghapus
                assessment sesuai kebutuhan.</span
              >
            </template>
            <InfoCircleOutlined class="ml-3" />
          </a-tooltip>
          <a-textarea
            class="mt-2"
            placeholder="Basic usage"
            :rows="8"
            v-model="patientVisitData.DoctorNote.assessment"
          />
        </div>
        <div class="col-6 mt-4">
          Plan<span style="color: red">*</span>

          <a-textarea
            class="mt-2"
            placeholder="Basic usage"
            :rows="8"
            v-model="patientVisitData.DoctorNote.plan"
          />
        </div>
      </div>
      <div v-if="userRole === 'koder'">
        <div>
          <p class="font-weight-bold">
            Diagnosis Primer<span class="text-danger">*</span>
          </p>

          <a-form-item v-for="(input, index) in primary_condition" :key="index">
            <div class="d-flex align-items-center" style="height: 2.5rem">
              <a-select
                show-search
                @search="searchICD10"
                :filter-option="false"
                :not-found-content="null"
                v-model="input.code"
                :default-active-first-option="false"
                :show-arrow="false"
                style="min-width: 15rem"
              >
                <a-select-option
                  v-for="(item, idx) in ICD10List"
                  :key="idx"
                  :value="item.skri + ' | ' + item.STR"
                >
                  {{ item.skri }} | {{ item.STR }}</a-select-option
                >
              </a-select>
              <div
                class="d-flex align-items-center justify-content-between ml-4"
              >
                <img
                  src="resources/images/x-black.svg"
                  alt="Icon"
                  style="height: 20px; width: 20px; cursor: pointer"
                  @click="removePrimaryInput(index)"
                />
              </div>
            </div>
          </a-form-item>

          <div
            @click="addPrimaryInput"
            v-if="primary_condition.length === 0"
            class="mt-2 h6 mb-3"
            style="color: #428a94"
          >
            <a>+ Tambah Kode ICD-10</a>
          </div>
        </div>
        <div class="mt-4">
          <p class="font-weight-bold">
            Diagnosis Sekunder<span class="text-danger">*</span>
          </p>

          <a-form-item
            v-for="(input, index) in secondary_condition"
            :key="index"
          >
            <div class="d-flex align-items-center" style="height: 2.5rem">
              <div class="d-flex">
                <a-select
                  show-search
                  @search="searchICD10"
                  :filter-option="false"
                  :not-found-content="null"
                  v-model="input.code"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  style="min-width: 15rem"
                >
                  <a-select-option
                    v-for="item in ICD10List"
                    :key="item.skri"
                    :value="item.skri + ' | ' + item.STR"
                  >
                    {{ item.skri }} | {{ item.STR }}</a-select-option
                  >
                </a-select>
                <div
                  class="d-flex align-items-center justify-content-between ml-4"
                >
                  <img
                    src="resources/images/x-black.svg"
                    alt="Icon"
                    style="height: 20px; width: 20px; cursor: pointer"
                    @click="handleSecondaryInput(index, 'delete')"
                  />
                  <img
                    src="resources/images/up-black.svg"
                    alt="Icon"
                    style="
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                      margin-left: 15px;
                    "
                    v-if="secondary_condition.length > 1 && index"
                    @click="handleSecondaryInput(index, 'up')"
                  />
                  <img
                    src="resources/images/down-black.svg"
                    alt="Icon"
                    style="
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                      margin-left: 15px;
                    "
                    v-if="
                      secondary_condition.length > 1 &&
                      index !== secondary_condition.length - 1
                    "
                    @click="handleSecondaryInput(index, 'down')"
                  />
                </div>
              </div>
            </div>
          </a-form-item>
          <p>Urutkan kode ICD dari diagnosis yang paling utama</p>
          <div
            @click="addSecondaryInput"
            class="mt-2 h6 mb-3"
            style="color: #428a94"
          >
            <a>+ Tambah Kode ICD-10</a>
          </div>
        </div>
        <div class="mt-4">
          <p class="font-weight-bold">Tindakan</p>
          <a-form-item
            v-for="(input, index) in tindakan_condition"
            :key="index"
          >
            <div class="d-flex align-items-center" style="height: 2.5rem">
              <div class="d-flex" style="width: 90%">
                <a-select
                  show-search
                  @search="searchICD9CM"
                  :filter-option="false"
                  :not-found-content="null"
                  v-model="input.code"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  style="min-width: 15rem"
                >
                  <a-select-option
                    v-for="item in ICD9CMList"
                    :key="item.CODE"
                    :value="item.CODE + ' | ' + item.STR"
                  >
                    {{ item.CODE }} | {{ item.STR }}</a-select-option
                  >
                </a-select>
                <div
                  class="d-flex align-items-center justify-content-between ml-4"
                >
                  <img
                    src="resources/images/x-black.svg"
                    alt="Icon"
                    style="height: 20px; width: 20px; cursor: pointer"
                    @click="handleProcedureInput(index, 'delete')"
                  />
                  <img
                    src="resources/images/up-black.svg"
                    alt="Icon"
                    style="
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                      margin-left: 15px;
                    "
                    v-if="tindakan_condition.length > 1 && index"
                    @click="handleProcedureInput(index, 'up')"
                  />
                  <img
                    src="resources/images/down-black.svg"
                    alt="Icon"
                    style="
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                      margin-left: 15px;
                    "
                    v-if="
                      tindakan_condition.length > 1 &&
                      index !== tindakan_condition.length - 1
                    "
                    @click="handleProcedureInput(index, 'down')"
                  />
                </div>
              </div>
            </div>
          </a-form-item>

          <div
            @click="addTindakanInput"
            class="mt-2 h6 mb-3"
            style="color: #428a94"
          >
            <a>+ Tambah Kode ICD-9</a>
          </div>
        </div>
      </div>
      <a-divider></a-divider>
      <div class="d-flex">
        <a-checkbox v-model="checkConsent"></a-checkbox>
        <p class="m-0 ml-3">
          Saya telah memverifikasi bahwa semua data medis pasien yang tercantum
          di atas adalah benar dan lengkap sesuai dengan hasil pemeriksaan yang
          telah dilakukan.
        </p>
      </div>
      <div class="d-flex align-items-center mt-4">
        <a-button
          type="primary"
          size="large"
          style="
            border-radius: 26px;
            width: 206px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          @click="changeSoapOrCode(userRole)"
          :disabled="!checkConsent"
        >
          <img
            src="resources/images/save.png"
            alt="Icon"
            style="height: 24px; width: 24px"
          />
          <p class="m-0 ml-2">Simpan Pemeriksaan</p>
        </a-button>
        <p
          class="m-0 ml-3"
          style="color: #428a94; cursor: pointer"
          @click="cancelHandleToggleModal"
        >
          Batal
        </p>
      </div>
    </a-modal>
    <a-modal
      v-model="modalConfirmVisible"
      title="Yakin Berkas Klaim sudah sesuai?"
      @ok="handleSubmitBerkas"
      centered
      :footer="null"
    >
      <p v-if="!clarificationStatus">
        Masih terdapat komentar klarifikasi yang masih dalam proses klarifikasi.
        Apabila Anda menekan Ya, komentar klarifikasi akan dianggap selesai.
      </p>
      <p>
        Mohon cek kembali konsistensi data diagnosis, tindakan/prosedur, dan
        berkas penunjang sebelum menyimpan hasil verifikasi.
      </p>
      <div class="d-flex justify-content-end mt-4">
        <button
          class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
          style="width: 50%; border-radius: 26px"
          @click.prevent="handleCloseModal"
        >
          <span class="ml-2">Kembali</span>
        </button>
        <button
          @click.prevent="handleSubmitBerkas"
          class="d-flex align-items-center justify-content-center btn btn-primary delete-button"
          style="width: 50%; border-radius: 26px"
        >
          <span class="ml-2">Ya</span>
        </button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getDokumenPenunjang,
  // saveCoderNote,
} from "@/services/axios/api/kunjungan"
import {
  createClarification,
  getAllClarification,
  getAllMessages,
  finishClarification,
  finishBerkas,
  getCodeLogs,
  getPatientVisit,
  updateSoapOrCode,
} from "@/services/axios/api/fileReview"
import {
  searchICD10Code,
  searchICD9CMCode,
  generateICD10Code,
  generateICD9CMCode,
} from "@/services/axios/api/icdCodes"
import { getUserProfileFromId } from "@/services/axios/api/auth"
import Viewer from "viewerjs"
import "viewerjs/dist/viewer.css"
import { io } from "socket.io-client"
import { InfoCircleOutlined } from "@ant-design/icons-vue"

export default {
  components: {
    InfoCircleOutlined,
  },
  data() {
    return {
      patientVisitData: {},
      patientVisitDocuments: [],
      visit_id: "",
      userId: "",
      userName: "",
      userRole: "",
      medicineStatus: [
        { label: "Active", value: "active" },
        { label: "Completed", value: "completed" },
        { label: "Entered in Error", value: "entered-in-error" },
        { label: "Intended", value: "intended" },
        { label: "Stopped", value: "stopped" },
        { label: "Draft", value: "draft" },
        { label: "On Hold", value: "on-hold" },
        { label: "Unknown", value: "unknown" },
        { label: "Not Taken", value: "not-taken" },
      ],
      rekomendasiVerifikasi: false,
      messages: [], // Daftar pesan
      newMessage: "", // Pesan yang akan dikirim
      socket: "",
      connected: false,
      templateKlarifikasi: {
        receiver: "",
        subject: "",
        comment: "",
      },
      listKlarifikasi: [],
      receiverList: [],
      toggleCreateClarification: false,
      detailClarification: {},
      chats: [
        {
          id: "",
          name: "",
          message: "",
          date: "",
        },
      ],
      replyChat: "",
      toggleModal: false,
      checkConsent: false,
      primary_condition: [],
      old_primary_condition: [],
      primary_recommendations: [],
      secondary_recommendations: [],
      old_secondary_condition: [],
      secondary_condition: [],
      ICD10List: [],
      tindakan_recommendations: [],
      old_tindakan_condition: [],
      tindakan_condition: [],
      ICD9CMList: [],
      patientConditions: [],
      patientDoctorNotes: [],
      codeLogList: [],
      soapLogList: [],
      detailLogCode: { id: "" },
      detailLogSoap: { id: "" },
      recommendationVerification: [],
      modalConfirmVisible: false,
      clarificationStatus: true,
    }
  },
  async created() {
    // this.socket = io("http://localhost:3011", {
    //   auth: {
    //     token: `Bearer ${localStorage.getItem("accessToken")}`,
    //   },
    // })
    this.socket = io("https://medisync.digitala.id", {
      auth: {
        token: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    this.socket.on("connect", () => {
      console.log("Connected to server:", this.socket.id)
      this.connected = true
    })

    // this.socket.emit('message', 'tesdarife')
    // this.socket.on('message', (message) => {
    //   console.log(message, 'inidaribe')
    // })
    this.socket.on("newMessage", async (msg) => {
      console.log("iniberapakali")
      if (msg.isFinish) {
        console.log("masuk sini ngga sih kocak")
        await this.getClarificationData()
      } else {
        // console.log("New message received:", msg)
        console.log(msg, "inichats")
        this.chats.push(msg) // Tambahkan pesan ke daftar chats
        await this.getClarificationData()
      }
    })
    this.socket.on("connect_error", (err) => {
      console.error("Socket.IO connection error:", err)
    })
    this.initiatData()
  },
  watch: {
    "$route.query": {
      immediate: true, // Jalankan langsung saat pertama kali komponen dibuat
      handler() {
        console.log("🔄 Query berubah, fetch ulang data...")
        this.initiatData()
      },
    },
  },
  beforeDestroy() {
    // Putuskan koneksi socket saat komponen dihancurkan
    if (this.socket) {
      this.socket.disconnect()
    }
  },
  methods: {
    async initiatData() {
      console.log(this.$route.query.chatRoom, "ini?")
      this.visit_id = this.$route.params.id
      this.userId = window.localStorage.getItem("id")
      this.userName = window.localStorage.getItem("name")
      this.userRole = window.localStorage.getItem("role")
      await this.getPatientVisitData()
      await this.getClarificationData()

      // this.socket = io('https://medicalsync.digitala.id')

      // this.joinRoom()
      // this.fetchMessages()
      // // Mendengarkan pesan baru dari server
      // this.socket.on('newMessage', (data) => {
      //   this.messages.push(data) // Tambahkan pesan baru ke UI
      // })
    },
    async handleSubmitBerkas() {
      const unfinishClarification = this.listKlarifikasi.filter(
        (e) => e.status === "Proses Klarifikasi",
      )
      for (let i = 0; i < unfinishClarification.length; i++) {
        const id = unfinishClarification[i].id
        await finishClarification(id)
        this.socket.emit("sendMessage", {
          roomId: this.detailClarification.id,
          isFinish: true,
        })
      }
      await finishBerkas(this.visit_id)

      this.$router.push(`/pemeriksaan-berkas`)
      this.clarificationStatus = true

      console.log(unfinishClarification)
    },
    handleToggleModal() {
      this.toggleModal = !this.toggleModal
    },
    openModalSubmit() {
      this.modalConfirmVisible = !this.modalConfirmVisible
    },
    handleCloseModal() {
      this.modalConfirmVisible = !this.modalConfirmVisible
    },
    setTab() {
      const role = window.localStorage.getItem("role")
      if (role === "perawat") {
        return "1"
      } else if (role === "dokter") {
        if (this.patientVisitData.status === "Selesai") {
          return "2"
        } else {
          return "1"
        }
      } else {
        return "1"
      }
    },
    openViewer(fileUri, file) {
      const imageElement = document.createElement("img")
      imageElement.src = fileUri
      imageElement.style.display = "none"
      document.body.appendChild(imageElement)
      console.log(imageElement, "image element")
      const viewer = new Viewer(imageElement, {
        inline: false,
        navbar: false,
        title: [
          1,
          (imageData) =>
            `${file.name ? file.name : ""} (${imageData.naturalWidth} x ${
              imageData.naturalHeight
            })`,
        ],
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          play: 1,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1,
        },
        viewed() {
          viewer.zoomTo(0.5)
        },
        hidden() {
          document.body.removeChild(imageElement)
        },
      })
      viewer.show()
    },
    formatText(text) {
      return text.replace(/\n/g, "<br>")
    },
    calculateAge(birthdate) {
      const today = new Date()
      const birthDate = new Date(birthdate)

      const years = today.getFullYear() - birthDate.getFullYear()
      const months = today.getMonth() - birthDate.getMonth()
      const days = today.getDate() - birthDate.getDate()

      let ageYears = years
      let ageMonths = months
      let ageDays = days

      if (ageDays < 0) {
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += prevMonth.getDate()
        ageMonths -= 1
      }

      if (ageMonths < 0) {
        ageMonths += 12
        ageYears -= 1
      }

      return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`
    },

    formatDateTime(datestring) {
      const date = new Date(datestring)
      const day = String(date.getDate()).padStart(2, "0")
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, "0")
      const minutes = String(date.getMinutes()).padStart(2, "0")
      const seconds = String(date.getSeconds()).padStart(2, "0")

      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
    },
    formatDateTimeFull(datestring) {
      const date = new Date(datestring)
      const day = String(date.getDate()).padStart(2, "0")
      const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ]
      const month = monthNames[date.getMonth()]
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, "0")
      const minutes = String(date.getMinutes()).padStart(2, "0")
      const seconds = String(date.getSeconds()).padStart(2, "0")

      return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`
    },
    async getUserName(id) {
      try {
        const response = await getUserProfileFromId(id)
        return response.data.user.name ? response.data.user.name : ""
      } catch (error) {
        console.error("Error fetching user name:", error)
        return "Unknown"
      }
    },
    async getHistoryDocumentImageUrl(file, visitId) {
      const response = await getDokumenPenunjang(visitId, file.id)
      const url = URL.createObjectURL(response.data)
      return url
    },
    async getPatientVisitData() {
      const response = await getPatientVisit(this.visit_id)
      const { data: logsData } = await getCodeLogs(this.visit_id)
      console.log(logsData.logs, "inilogdata")
      this.codeLogList = logsData.logs.map((e) => {
        console.log(e, "inilogdetail")
        const oldPrimary = e.CodeLogDetails.filter(
          (el) => el.codeType === "primary" && el.versionType === "old",
        )
        const primary = e.CodeLogDetails.filter(
          (el) => el.codeType === "primary" && el.versionType === "new",
        )
        const oldSecondary = e.CodeLogDetails.filter(
          (el) => el.codeType === "secondary" && el.versionType === "old",
        )
        const secondary = e.CodeLogDetails.filter(
          (el) => el.codeType === "secondary" && el.versionType === "new",
        )
        const oldProcedure = e.CodeLogDetails.filter(
          (el) => el.codeType === "procedure" && el.versionType === "old",
        )
        const procedure = e.CodeLogDetails.filter(
          (el) => el.codeType === "procedure" && el.versionType === "new",
        )
        const formattedDate = this.formatDateTime(e.createdAt)

        return {
          ...e,
          primary,
          oldPrimary,
          secondary,
          oldSecondary,
          procedure,
          oldProcedure,
          date: formattedDate,
        }
      })
      this.soapLogList = logsData.logsSoap.map((e) => {
        console.log(e, "inilogdetailsoap")
        const oldSubjective = e.SoapLogDetails.filter(
          (el) => el.soapType === "subjective" && el.versionType === "old",
        )
        const subjective = e.SoapLogDetails.filter(
          (el) => el.soapType === "subjective" && el.versionType === "new",
        )
        console.log("errorsoapsini?")
        const oldObjective = e.SoapLogDetails.filter(
          (el) => el.soapType === "objective" && el.versionType === "old",
        )
        const objective = e.SoapLogDetails.filter(
          (el) => el.soapType === "objective" && el.versionType === "new",
        )
        const oldAssessment = e.SoapLogDetails.filter(
          (el) => el.soapType === "assessment" && el.versionType === "old",
        )
        const assessment = e.SoapLogDetails.filter(
          (el) => el.soapType === "assessment" && el.versionType === "new",
        )
        const oldPlan = e.SoapLogDetails.filter(
          (el) => el.soapType === "plan" && el.versionType === "old",
        )
        const plan = e.SoapLogDetails.filter(
          (el) => el.soapType === "plan" && el.versionType === "new",
        )
        const formattedDate = this.formatDateTime(e.createdAt)
        return {
          ...e,
          oldSubjective,
          subjective,
          oldObjective,
          objective,
          oldAssessment,
          assessment,
          oldPlan,
          plan,
          date: formattedDate,
        }
      })
      console.log(this.soapLogList, "iniceksoaploglist")
      this.patientVisitData = response.data.visit

      this.patientVisitData = {
        ...this.patientVisitData,
        age: this.calculateAge(this.patientVisitData.Patient.birth_date),
        birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
        gender:
          this.patientVisitData.Patient.gender === "Male"
            ? "Laki-laki"
            : "Perempuan",
        payment_method:
          this.patientVisitData.Patient.payment_method === "JKN"
            ? "JKN (BPJS Kesehatan)"
            : this.patientVisitData.Patient.payment_method,
        checkup_time: this.formatDateTime(this.patientVisitData.checkup_time),
        checkup_date: this.formatDate(this.patientVisitData.checkup_time),
        nurse_name: await this.getUserName(
          this.patientVisitData.attending_nurse_id,
        ),
      }
      const formattedDateVisit = new Intl.DateTimeFormat("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }).format(new Date(this.patientVisitData.createdAt))
      this.patientVisitData.date = formattedDateVisit
      this.patientVisitData.DoctorNote.oldSubjective =
        this.patientVisitData.DoctorNote.subjective
      this.patientVisitData.DoctorNote.oldObjective =
        this.patientVisitData.DoctorNote.objective
      this.patientVisitData.DoctorNote.oldAssessment =
        this.patientVisitData.DoctorNote.assessment
      this.patientVisitData.DoctorNote.oldPlan =
        this.patientVisitData.DoctorNote.plan

      for (const file in response.data.documents) {
        // this.patientVisitDocuments.push({
        //     name: this.patientVisitData.documents[file].name,
        //     url: this.patientVisitData.documents[file].url,
        // })
        response.data.documents[file].created_by_name = await this.getUserName(
          response.data.documents[file].VisitDocument.created_by,
        )
        response.data.documents[file].uri =
          await this.getHistoryDocumentImageUrl(
            response.data.documents[file],
            this.patientVisitData.id,
          )
      }
      this.patientVisitDocuments = response.data.documents
      console.log(this.patientVisitData, "data kunjungan pasien")
      const userList = this.patientVisitData.Conditions.map((e) => {
        // console.log(e.User.id)
        return {
          id: e.User.id,
          name: e.User.name,
          role: e.User.Roles[0].name,
        }
      })
      let isKoder = false
      this.patientConditions = this.patientVisitData.Conditions.map((e) => {
        if (e.User.Roles[0].name === "koder") {
          isKoder = true
        }
        return {
          ...e,
          role: e.User.Roles[0].name,
        }
      })
      if (isKoder) {
        this.patientConditions = this.patientConditions.filter(
          (e) => e.role === "koder",
        )
      }
      this.receiverList = userList.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id),
      )

      console.log(this.receiverList)
      // console.log(this.patientVisitDocuments, 'dokumen kunjungan pasien')

      if (this.patientVisitData.Conditions.length) {
        const coderCondition = this.patientVisitData.Conditions.filter(
          (e) => e.User.Roles[0].name === "koder",
        )

        console.log(coderCondition, "inicodercondition")

        const primaryCondition = coderCondition.length
          ? coderCondition.filter((e) => e.condition_type === "primary")
          : this.patientVisitData.DoctorNote.Conditions.filter(
              (e) => e.condition_type === "primary",
            )

        console.log(primaryCondition, "cekiniprimarycondition")
        const secondaryCondition = coderCondition
          ? coderCondition.filter((e) => e.condition_type === "secondary")
          : this.patientVisitData.DoctorNote.Conditions.filter(
              (e) => e.condition_type === "secondary",
            )

        const codePrimary = `${primaryCondition[0].code} | ${primaryCondition[0].STR}`
        this.old_primary_condition = []
        this.primary_condition = []
        this.old_primary_condition.push({
          ...primaryCondition[0],
          code: codePrimary,
        })

        this.primary_condition.push({
          ...primaryCondition[0],
          code: codePrimary,
        })
        this.old_secondary_condition = []
        this.secondary_condition = []
        secondaryCondition.forEach((e) => {
          const codeSecondary = `${e.code} | ${e.STR}`
          this.secondary_condition.push({
            ...e,
            code: codeSecondary,
          })
          this.old_secondary_condition.push({
            ...e,
            code: codeSecondary,
          })
        })
      }
      if (this.patientVisitData.Procedures.length) {
        console.log(this.patientVisitData.Procedures, "iniprocedure")
        const coderProcedure = this.patientVisitData.Procedures.filter(
          (e) => e.User.Roles[0].name === "koder",
        )
        this.old_tindakan_condition = []
        this.tindakan_condition = []
        this.tindakan_condition = coderProcedure.length
          ? coderProcedure.map((e) => {
              const codeTindakan = `${e.code} | ${e.STR}`
              return {
                ...e,
                code: codeTindakan,
              }
            })
          : this.patientVisitData.Procedures.map((e) => {
              const codeTindakan = `${e.code} | ${e.STR}`
              return {
                ...e,
                code: codeTindakan,
              }
            })
        this.old_tindakan_condition = coderProcedure.length
          ? coderProcedure.map((e) => {
              const codeTindakan = `${e.code} | ${e.STR}`
              return {
                ...e,
                code: codeTindakan,
              }
            })
          : this.patientVisitData.Procedures.map((e) => {
              const codeTindakan = `${e.code} | ${e.STR}`
              return {
                ...e,
                code: codeTindakan,
              }
            })
      }
      console.log(this.patientVisitData, "apaini")
      if (this.patientVisitData.ExaminationRequests.length) {
        const status = this.patientVisitData.ExaminationRequests[0].status
        const examinations = []
        // console.log(status, 'inistatuspenunjang')
        if (status !== "Selesai Diperiksa") {
          this.rekomendasiVerifikasi = true
          for (
            let i = 0;
            i < this.patientVisitData.ExaminationRequests.length;
            i++
          ) {
            const dataRequest =
              this.patientVisitData.ExaminationRequests[i]
                .ExaminationRequestDetails

            for (let j = 0; j < dataRequest.length; j++) {
              examinations.push(dataRequest[j].MasterExamination.name)
            }
          }
          this.recommendationVerification = examinations
        }
      }
    },
    async getClarificationData() {
      const userId = window.localStorage.getItem("id")
      const { data } = await getAllClarification(
        this.patientVisitData.id,
        userId,
      )
      console.log(data.klarifikasi, "iniclarificationdata")
      this.listKlarifikasi = data.klarifikasi.map((e) => {
        const formattedDate = new Intl.DateTimeFormat("id-ID", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(new Date(e.createdAt))

        if (e.status === "Proses Klarifikasi") {
          this.clarificationStatus = false
        }
        // console.log(e, "inibro")
        const latestChat = e.KlarifikasiChats.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        )[0]
        return {
          ...e,
          receiver: e.ReceiverUser.name,
          receiverId: e.ReceiverUser.id,
          sender: e.SenderUser.name,
          senderId: e.SenderUser.id,
          date: formattedDate,
          KlarifikasiChats: latestChat,
        }
      })
      if (this.$route.query.chatRoom) {
        const room = this.listKlarifikasi.filter(
          (e) => e.id === this.$route.query.chatRoom,
        )
        await this.handleDetailKlarifikasi(room[0])
      }
    },

    // Mengambil riwayat pesan
    async fetchMessages() {
      try {
        const response = await fetch(`/api/file-review/messages/${this.roomId}`)
        const data = await response.json()
        this.messages = data
      } catch (error) {
        console.error("Error fetching messages:", error)
      }
    },
    async createClarificationData() {
      console.log(this.patientVisitData.Doctor.User.name)
      console.log(this.patientVisitData.id)
      const userId = window.localStorage.getItem("id")
      await createClarification({
        ...this.templateKlarifikasi,
        sender: userId,
        idKunjungan: this.patientVisitData.id,
        name: this.userName,
      })
      this.templateKlarifikasi = {
        receiver: "",
        subject: "",
        comment: "",
      }
      await this.getClarificationData()
      this.toggleCreateClarification = false
      // console.log(res, 'ini res')
    },
    async changeSoapOrCode(type) {
      if (type === "dokter") {
        let isChangeExist = false

        if (
          this.patientVisitData.DoctorNote.oldSubjective !==
          this.patientVisitData.DoctorNote.subjective
        ) {
          console.log("masuk1")
          isChangeExist = true
        }
        if (
          this.patientVisitData.DoctorNote.oldObjective !==
          this.patientVisitData.DoctorNote.objective
        ) {
          console.log("masuk2")

          isChangeExist = true
        }
        if (
          this.patientVisitData.DoctorNote.oldAssessment !==
          this.patientVisitData.DoctorNote.assessment
        ) {
          console.log(this.patientVisitData.DoctorNote.oldAssessment)
          console.log(this.patientVisitData.DoctorNote.assessment)
          console.log("masuk3")

          isChangeExist = true
        }
        if (
          this.patientVisitData.DoctorNote.oldPlan !==
          this.patientVisitData.DoctorNote.plan
        ) {
          console.log("masuk4")

          isChangeExist = true
        }

        if (!isChangeExist) {
          this.$notification.warning({
            message: "Tidak ada perubahan",
            description: "Tidak ada SOAP yang berubah.",
            duration: 5,
          })
        } else {
          await updateSoapOrCode({
            type: this.userRole,
            idKunjungan: this.patientVisitData.id,
            subjective: this.patientVisitData.DoctorNote.subjective,
            oldSubjective: this.patientVisitData.DoctorNote.oldSubjective,
            oldObjective: this.patientVisitData.DoctorNote.oldObjective,
            objective: this.patientVisitData.DoctorNote.objective,
            oldPlan: this.patientVisitData.DoctorNote.oldPlan,
            plan: this.patientVisitData.DoctorNote.plan,
            oldAssessment: this.patientVisitData.DoctorNote.oldAssessment,
            assessment: this.patientVisitData.DoctorNote.assessment,
          })
          await this.getPatientVisitData()
          await this.getClarificationData()
          this.toggleModal = false
          this.checkConsent = false
          console.log("masuksini")
        }
        // console.log(this.patientVisitData.DoctorNote, "cekinibro")
      } else {
        console.log("masuksinikah?")
        const obj = {
          visit_id: this.visit_id,
          primaryCondition: this.primary_condition,
          oldPrimaryCondition: this.old_primary_condition,
          secondaryConditions: this.secondary_condition,
          oldSecondaryConditions: this.old_secondary_condition,
          procedures: this.tindakan_condition,
          oldProcedures: this.old_tindakan_condition,
          type: this.userRole,
          idKunjungan: this.patientVisitData.id,
        }
        console.log(obj.oldProcedures)
        console.log(obj.procedures)
        await updateSoapOrCode(obj)

        await this.getPatientVisitData()
        await this.getClarificationData()
        this.toggleModal = false
        this.checkConsent = false
      }
    },
    handleToggleClarificationForm() {
      this.toggleCreateClarification = !this.toggleCreateClarification
    },
    formatDate(dateString) {
      const date = new Date(dateString)

      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Format 24-jam
      }

      // Format tanggal dengan koma setelah tanggal
      let formattedDate = new Intl.DateTimeFormat("id-ID", options).format(date)
      formattedDate = formattedDate.replace(" pukul ", ", ").replace(".", ":")
      return formattedDate
    },

    // Hitung Waktu Relatif
    getRelativeTime(dateString) {
      const now = new Date()
      const date = new Date(dateString)

      const diffInMilliseconds = now - date
      const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
      const diffInMinutes = Math.floor(diffInSeconds / 60)
      const diffInHours = Math.floor(diffInMinutes / 60)
      const diffInDays = Math.floor(diffInHours / 24)

      if (diffInDays > 0) {
        return `${diffInDays} hari lalu`
      } else if (diffInHours > 0) {
        return `${diffInHours} jam lalu`
      } else if (diffInMinutes > 0) {
        return `${diffInMinutes} menit lalu`
      } else {
        return "baru saja"
      }
    },
    async handleDetailKlarifikasi(item) {
      // console.log(item, 'cekini')
      this.detailClarification = item
      // Emit event joinRoom
      this.socket.emit("joinRoom", { roomId: item.id })
      console.log(`Joined room: ${item.id}`)
      console.log(this.detailClarification, "inidetail")
      const { data } = await getAllMessages(item.id)
      console.log(data.messages, "ini history chat")
      this.chats = data.messages.map((e) => {
        const formattedDate = this.formatDate(e.createdAt)
        const relativeTime = this.getRelativeTime(e.createdAt)
        return {
          id: e.sender,
          name: e.name,
          message: e.message,
          date: `${formattedDate} (${relativeTime})`,
        }
      })
    },
    cancelHandleToggleModal() {
      this.toggleModal = false
    },
    cancelDetailClarification() {
      this.detailClarification = {}
    },
    async handleCreateChat() {
      console.log(this.detailClarification)
      console.log("masukberapakali?")
      this.socket.emit("sendMessage", {
        roomId: this.detailClarification.id,
        id: this.userId,
        message: this.replyChat,
        name: this.userName,
        receiverId:
          this.userId === this.detailClarification.receiverId
            ? this.detailClarification.senderId
            : this.detailClarification.receiverId,
        idKunjungan: this.visit_id,
      })
      // console.log("iniada?")

      this.replyChat = ""
    },
    async handleFinishClarification() {
      await finishClarification(this.detailClarification.id)
      await this.getClarificationData()
      this.socket.emit("sendMessage", {
        roomId: this.detailClarification.id,
        isFinish: true,
      })
      this.detailClarification = {}
    },
    async generateICD10(payloadData, primaryCode, secondaryCode) {
      let prompt = `\n Primary Diagnosis : `
      if (primaryCode.length) {
        primaryCode.forEach((e) => {
          prompt += `\n - ${e.code}`
        })
      }
      if (secondaryCode.length) {
        prompt += `\nSecondary Diagnosis :`
        secondaryCode.forEach((e) => {
          prompt += `\n - ${e.code} | ${e.STR}`
        })
      }
      this.ICD10Loading = true
      const assessment = payloadData
      const payload = {
        text: assessment + prompt,
      }
      console.log(assessment + prompt)
      const res = await generateICD10Code(payload)

      const data = res.data.codes
      if (data.length > 0) {
        const formattedData = data.map((item) => {
          return {
            ...item,
            code: item.skri + " | " + item.STR,
          }
        })
        // this.primary_condition = []
        // this.primary_condition_autoGenerated = []
        // this.primary_condition_autoGenerated = formattedData.slice(0, 1)

        if (formattedData.length > 0) {
          this.conditions_autoGenerated = formattedData
          console.log(formattedData, "tesini")
          this.primary_recommendations = []
          this.secondary_recommendations = []
          formattedData.forEach((e) => {
            if (e.type === "Primary") {
              this.primary_recommendations.push(e)
            } else {
              this.secondary_recommendations.push(e)
            }
          })
          console.log(this.conditions_autoGenerated, "niya")
        }
        // console.log(this.primary_condition_autoGenerated, 'primary_condition_autoGenerated')
        // console.log(this.secondary_conditions_autoGenerated, 'secondary_conditions_autoGenerated')
      } else {
        this.$notification.warning({
          message: "Peringatan",
          description: "Tidak ada kode ICD-10 yang dihasilkan",
          duration: 5,
        })
      }
      this.ICD10Loading = false
    },
    async getICD10Codes(search) {
      const ICD10response = await searchICD10Code(search)
      this.ICD10List = ICD10response.data.codes
      // console.log(this.ICD10List, 'ICD10 List')
    },
    async searchICD10(query) {
      if (query) {
        try {
          // console.log('Searching ICD10 codes:', query)
          const response = await searchICD10Code(query)
          this.ICD10List = response.data.codes
          // console.log('ICD10 codes:', this.ICD10List)
        } catch (error) {
          console.error("Error fetching ICD10 codes:", error)
          this.ICD10List = []
        }
      } else {
        this.ICD10List = []
      }
    },
    async generateICD9CM(payloadData) {
      console.log(payloadData, "iniplan")
      this.ICD9CMLoading = true
      try {
        const payload = {
          text: payloadData,
        }
        const res = await generateICD9CMCode(payload)
        const data = res.data.codes
        if (data.length > 0) {
          const formattedData = data.map((item) => ({
            ...item,
            code: item.CODE + " | " + item.STR,
          }))
          console.log(formattedData, "cek ini")
          this.tindakan_recommendations = formattedData
        } else {
          this.$notification.warning({
            message: "Peringatan",
            description: "Tidak ada kode ICD-9-CM yang dihasilkan",
            duration: 5,
          })
        }
      } catch (error) {
        console.error("Error generating ICD-9-CM:", error)
        this.$notification.error({
          message: "Gagal",
          description: "Terjadi kesalahan saat menghasilkan kode ICD-9-CM",
          duration: 5,
        })
      }
      this.ICD9CMLoading = false
    },
    async getICD9CMCodes(search) {
      const ICD9CMresponse = await searchICD9CMCode(search)
      this.ICD9CMList = ICD9CMresponse.data.codes
      // console.log(this.ICD9CMList, 'ICD9CM List')
    },
    async searchICD9CM(query) {
      if (query) {
        try {
          // console.log('Searching ICD9CM codes:', query)
          const response = await searchICD9CMCode(query)
          this.ICD9CMList = response.data.codes
          console.log("ICD9CM codes:", this.ICD9CMList)
        } catch (error) {
          console.error("Error fetching ICD9CM codes:", error)
          this.ICD9CMList = []
        }
      } else {
        this.ICD9CMList = []
      }
    },
    addSecondaryInput() {
      this.secondary_condition.push({ code: "" })
      // console.log(this.secondary_conditions, 'secondary_conditions')
    },
    addTindakanInput() {
      this.tindakan_condition.push({ code: "" })
      // console.log(this.secondary_conditions, 'secondary_conditions')
    },

    addPrimaryConditions(text) {
      const newObj = {
        ...text,
      }
      console.log(text, "iniliat")
      const newCondition = [newObj]
      this.primary_condition = newCondition
    },
    addSecondaryConditions(text) {
      const newObj = {
        ...text,
      }
      this.secondary_condition.push(newObj)
    },
    addTindakanConditions(text) {
      const newObj = {
        ...text,
        status: "preparation",
      }
      this.tindakan_condition.push(newObj)
    },
    addPrimaryInput() {
      this.primary_condition.push({ code: "" })
      // console.log(this.primary_condition, 'primary_condition')
    },
    removePrimaryInput(index) {
      this.primary_condition.splice(index, 1)
    },
    handleSecondaryInput(index, type) {
      switch (type) {
        case "up":
          if (index > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.secondary_condition[index]
            this.$set(
              this.secondary_condition,
              index,
              this.secondary_condition[index - 1],
            )
            this.$set(this.secondary_condition, index - 1, temp)
          }
          break
        case "down":
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (index < this.secondary_condition.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.secondary_condition[index]
            this.$set(
              this.secondary_condition,
              index,
              this.secondary_condition[index + 1],
            )
            this.$set(this.secondary_condition, index + 1, temp)
          }
          break
        case "delete":
          this.secondary_condition.splice(index, 1)
          break
      }
    },
    handleProcedureInput(index, type) {
      switch (type) {
        case "up":
          if (index > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.tindakan_condition[index]
            this.$set(
              this.tindakan_condition,
              index,
              this.tindakan_condition[index - 1],
            )
            this.$set(this.tindakan_condition, index - 1, temp)
          }
          break
        case "down":
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (index < this.tindakan_condition.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.tindakan_condition[index]
            this.$set(
              this.tindakan_condition,
              index,
              this.tindakan_condition[index + 1],
            )
            this.$set(this.tindakan_condition, index + 1, temp)
          }
          break
        case "delete":
          this.tindakan_condition.splice(index, 1)
          break
      }
    },
    removeTindakanInput(index) {
      this.tindakan_condition.splice(index, 1)
    },
    handleChangeLogCode(item) {
      console.log(item)
      this.detailLogCode = item
    },
    handleChangeLogSoap(item) {
      console.log(item)
      this.detailLogSoap = item
    },
    handleBackLogCodeDetail() {
      this.detailLogCode = {}
    },
    handleBackLogSoapDetail() {
      this.detailLogSoap = {}
    },
  },
}
</script>

<style scoped>
.text-color-green {
  color: #428a94;
}

.background-blue {
  background-color: #f6feff;
}

.table thead {
  border-bottom: 1px solid #e5e5e5;
}

.table th,
.table td {
  border: none;
  padding: 8px;
}

.SOAP-title-color {
  color: #428a94;
}

.image-container {
  position: relative;
  display: inline-block;
  margin: 0;
}

.zoom-button {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 999px;
}

.zoom-button:hover {
  background-color: rgba(255, 255, 255, 1);
}
</style>
